
.box-shadow {
  box-shadow: 0 .25rem .45rem rgba(0, 0, 0, .15);
}


header {
  background-color: #000;

  a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
  }

  a.nav-link {
    text-transform: uppercase;
    font-size: large !important;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 0;
  }

  a.nav-link:hover {
    color: white !important;
  }
}