@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.background {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  // background-image: linear-gradient(rgb(229, 255, 232), rgb(223, 247, 255));
  background: url("../../../public/bg3.jpg") no-repeat 100%/cover;
}

.rowform {
  display: flex;
  align-self: center;
  margin: 1rem auto;
  width: min-content;
  background-color: white;
  // color: white;
  padding: 0;
  overflow: hidden;
  border-radius: 1rem;
  height: min-content;
  box-shadow: 0 .25rem .45rem rgba(0, 0, 0, .35);

  img {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  button {
    font-weight: bold;
    letter-spacing: 1.5px;
    // text-transform: uppercase;
  }

  input, button {
    width: 30rem;
    display: inline;
  }

  @media screen and (max-width: calc(30rem + 2rem)) {
    input, button {
      width: calc(100vw - 4.5rem);
    }
    width: calc(100% - 1rem);
    margin: 0.5rem;
  }
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.landing-page {
    min-height: 100vh;
    color: #222;

    .message {
        max-width: 50rem;
        white-space: pre-wrap;
        font-size: 1.5rem;
        word-wrap: break-word;
        font-family: 'Poppins', sans-serif;
    }

    @media screen and (max-width: 1000px) {
        .message {
            font-size: 1rem;
        }
    }
}

.LandingPage-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LandingPage-overlay * {
  animation: slide-in 0.5s forwards;
  transform: translateX(-200%);
}

.file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > div {
    // border: 1px solid #F39200;
    text-align: center;
    background-color: #000;
    box-shadow: 3px 3px 3px 1px #0005;
    padding: 1rem;
    border-radius: 1rem;

    > * {
      margin: 1rem 0;
    }
  }
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}