.BildeveRowForm{
    background-color: #1F438D;
    color: white;
    padding: 1.7rem 2rem;
    // font-family: "volvo";
    Input, Label, Button{
        width: 20rem !important;
    }
    Input{
        margin-bottom: 1.5rem;
    }
    .regnr{
        box-shadow: 0px 0px 10px 1px #fff3;
        font-size: 2rem;
        text-align: center;
        width: 12.5rem;
        max-width: 12.5rem;
        padding: 0 1rem;
        padding-left: 1.5rem;
        border: 3px solid black;
        background: linear-gradient(to right, #2257A0 1rem, white 1rem);
    }
    .check{
        width: 1.5rem; 
        height: 1.5rem;  
        padding: 0;  
        margin-right: 1rem;  
        border-radius: 2px; 
        background-color: #1E4290;
        border-color: white;
        &:disabled{
            background-color: grey;
        }
    }
    .submittt{
        background-color: white; 
        color: #1E4290; 
        border-width: 2px; 
        border-color: #1E4290; 
        border-radius: 1.5rem; 
        width: 13rem;
        &:hover{
            background-color: #e2ebff; 
        }
    }
}
// @font-face {
//   font-family: "volvo";
//   src: url("./BildeveRowForm.otf") format("opentype");
// }